var SphinxClass = (function () {
        function e() {
            (this.credentials = null),
                (this.productUrl = ''),
                (this.params = null),
                (this.iframeName = ''),
                (this.jsessionid = null),
                (this.response = null),
                (this.cid = null),
                (this.intervalID = null),
                (this.isLoggedIn = !1),
                (this.userNotifiedOfBadLogin = !1),
                (this.onLoginFailure = function (e) {}),
                (this.onFinished = function (e) {}),
                (this.afterExportDossier = function (e) {});
        }
        var n = null,
            t =
                ((e.prototype.setProductUrl = function (e) {
                    return (this.productUrl = e), this;
                }),
                (e.prototype.setIframeName = function (e) {
                    return (this.iframeName = e), this;
                }),
                (e.prototype.isLoggedIn = function () {
                    return this.isLoggedIn;
                }),
                (e.prototype.checkForMessages = function () {
                    var e, n, t;
                    1 == window.location.hash.indexOf('ready')
                        ? (window.clearInterval(sphinxFL.intervalID),
                          (e = window.location.hash.replace('#', '').split('~')),
                          (window.location.hash = ''),
                          3 == e.length && ((t = e[2].split('_')), (sphinxFL.cid = t[1])),
                          null != sphinxFL.cid && sphinxFL.onFinished())
                        : 1 == window.location.hash.indexOf('error')
                          ? (window.clearInterval(sphinxFL.intervalID),
                            (e = window.location.hash.replace('#', '').split('~')),
                            (window.location.hash = ''),
                            void 0 !== e[1] &&
                                ((t = decodeURIComponent(e[1].split('_')[1].replace(/\+/g, '%20'))),
                                (n = ''),
                                void 0 !== e[2] &&
                                    (n = '\n' + decodeURIComponent(e[2].split('_')[1].replace(/\+/g, '%20'))),
                                alert(t + n)),
                            void 0 !== e[3] &&
                                ((t = decodeURIComponent(e[3].split('_')[1].replace(/\+/g, '%20'))),
                                (document.getElementById(sphinxFL.iframeName).src = t),
                                window.setInterval(sphinxFL.checkForMessages, 500)))
                          : 1 == window.location.hash.indexOf('timeout') &&
                            (window.clearInterval(sphinxFL.intervalID), alert('timeout'));
                }),
                []);
        for (
            ('undefined' != typeof window && window && void 0 !== window.jQuery && window.jQuery) ||
                t.push('https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js'),
                index = 0;
            index < t.length;
            index++
        ) {
            var i = document.createElement('script');
            (i.type = 'text/javascript'),
                (i.async = !0),
                (i.src = t[index]),
                (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(i);
        }
        var o = new Date();
        return (
            (function e() {
                if (!window.jQuery)
                    if (new Date() - o <= 6e4) setTimeout(e, 10);
                    else {
                        var n = "Necessary files coudn't be loaded in time:\n\n";
                        for (index = 0; index < t.length; index++) n += t[index] + '\n';
                        (n += '\nvaluateFLExternal aborted!'), alert(n);
                    }
            })(),
            (e.prototype.login = function (e) {
                $.ajax({
                    type: 'GET',
                    url: this.productUrl + 'post.external',
                    jsonpCallback: 'sphinxFL.loginCallback',
                    contentType: 'application/json',
                    dataType: 'jsonp',
                    crossDomain: !0,
                    data: {
                        response: 'jsonp',
                        action: 'login',
                        customerNumber: sphinxFL.credentials.customerNumber,
                        customerLogin: sphinxFL.credentials.customerLogin,
                        customerSignature: sphinxFL.credentials.customerSignature,
                        interfacePartnerNumber: sphinxFL.credentials.interfacePartnerNumber,
                        interfacePartnerSignature: sphinxFL.credentials.interfacePartnerSignature,
                        'DAT-AuthorizationToken': sphinxFL.credentials.token,
                        hostUrl: sphinxFL.hostUrl,
                        clientLocation: window.location.origin,
                    },
                    complete: e,
                });
            }),
            (e.prototype.loginCallback = function (e) {
                'SUCCESS' != e.result
                    ? ((this.isLoggedIn = !1), (this.userNotifiedOfBadLogin = !0), this.onLoginFailure(e))
                    : ((this.isLoggedIn = !0), (this.jsessionid = e.jsessionid));
            }),
            (e.prototype.sendDossierRequest = function () {
                if (
                    (sphinxFL.isLoggedIn ||
                        sphinxFL.userNotifiedOfBadLogin ||
                        sphinxFL.login(sphinxFL.sendDossierRequest),
                    sphinxFL.isLoggedIn)
                ) {
                    sphinxFL.intervalID = window.setInterval(sphinxFL.checkForMessages, 500);
                    var e,
                        n,
                        t = $('<form>', {
                            action: sphinxFL.productUrl + 'post.external',
                            method: 'POST',
                            target: sphinxFL.iframeName,
                        });
                    for (e in sphinxFL.params)
                        sphinxFL.params.hasOwnProperty(e) &&
                            ((n = $('<input>', { type: 'hidden', name: e }).attr('value', sphinxFL.params[e])),
                            t.append(n));
                    t.appendTo('body').submit(), t.remove();
                }
            }),
            (e.prototype.exportDossier = function () {
                $.ajax({
                    type: 'GET',
                    url: this.productUrl + 'post.external',
                    jsonpCallback: 'sphinxFL.exportDossierCallback',
                    contentType: 'application/json',
                    dataType: 'jsonp',
                    crossDomain: !0,
                    data: { response: 'jsonp', action: 'exportDossier', cid: this.cid },
                });
            }),
            (e.prototype.exportDossierCallback = function (e) {
                e && e.redirect ? (window.location = e.redirect) : ((this.response = e), this.afterExportDossier());
            }),
            (e.prototype.logout = function () {
                $.ajax({
                    type: 'GET',
                    url: this.productUrl + 'post.external',
                    jsonpCallback: 'sphinxFL.logoutCallback',
                    contentType: 'application/json',
                    dataType: 'jsonp',
                    crossDomain: !0,
                    data: { response: 'jsonp', action: 'logout' },
                });
            }),
            (e.prototype.logoutCallback = function (e) {
                (this.isLoggedIn = !1), (this.jsessionid = null);
            }),
            {
                getInstance: function () {
                    return null == n && ((n = new e()).constructor = null), n;
                },
            }
        );
    })(),
    sphinxFL = SphinxClass.getInstance();
